<template>
  <div>
    <vx-card actionable class="cardx" title="Manage Queue">
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            id="courseselect"
            class="w-full"
            placeholder="City"
            v-model="city"
            :options="cities"
            label="text"
            style="height: 20px; z-index: 1000"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-table :data="CitySpocs" style="width: 100%" :hoverFlat="true">
          <template slot="thead">
            <vs-th>City</vs-th>
            <vs-th>SPOC Name</vs-th>
            <vs-th>Focused Levels</vs-th>
            <vs-th>Apply</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="tr.id" v-for="tr in data">
                <vs-td>
                  <div>{{ tr.city }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.full_name }}</div>
                </vs-td>
                <vs-td>
                  <div>
                    {{ tr.queue_level }}
                    <!-- <treeselect
                      v-model="tr.levelsSelected"
                      :multiple="true"
                      :options="treeDataLevels"
                      style="width: 240px;"
                    /> -->
                    <!-- <input type="color" v-model="colorx" name="" value="" /> -->
                    <!-- <vs-button
                      color="#975846"
                      type="border"
                      @click="givePermission(tr)"
                      >Grant Permission</vs-button
                    > -->
                  </div>
                </vs-td>
                <vs-td>
                  <div>
                    <vs-button color="dark" type="border" @click="openPopup(tr)"
                      >Manage</vs-button
                    >
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vs-row>
    </vx-card>
    <vs-popup
      class="holamundo"
      title="Manage Queue"
      :active.sync="open"
      :id="'manageQueue'"
    >
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="flex-start"
          vs-w="12"
        >
          <treeselect
            v-if="open"
            @select="removeUnrequiredOptions"
            v-model="selectedSpoc.levelsSelected"
            :multiple="true"
            :options="treeDataLevels"
            style="width: 240px;"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 5%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <v-select
            :options="enrollments"
            v-model="selected_enrollment"
            style="width: 240px;"
          />
        </vs-col>
      </vs-row>
      <vs-divider v-if="checkEnrollmentSelection">
        {{ generateName }}
      </vs-divider>
      <vs-row style="margin-bottom: 5%" v-if="checkEnrollmentSelection">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-range-picker
            :opens="'center'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range'
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="dateRange"
            style="width: 250px;position:absolute"
          ></date-range-picker>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 5%;">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="dark"
            type="border"
            @click="applySetting(selectedSpoc)"
            >Save
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus";
import vSelect from "vue-select";
// import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";

export default {
  data() {
    return {
      open: false,
      colorx: [],
      enrollments: ["CPA", "CMA"],
      selected_enrollment: "",
      dateRange: {
        startDate: "",
        endDate: ""
      },
      currentTablePage: 1,
      tablelinks: 1,
      city: "",
      cities: [],
      spoc_names: [],
      CitySpocs: [],
      filter: "pending",
      tabColor: "warning",
      selectedSpoc: {
        levelsSelected: ""
      },
      hours: 1,
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3"
                },
                {
                  id: "M3+",
                  label: "M3+"
                },
                {
                  id: "M3++",
                  label: "M3++"
                },
                {
                  id: "M5",
                  label: "M5"
                },
                {
                  id: "M6",
                  label: "M6"
                }
              ]
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2"
                },
                {
                  id: "L3",
                  label: "L3"
                },
                {
                  id: "L4",
                  label: "L4"
                },
                {
                  id: "L6",
                  label: "L6"
                },
                {
                  id: "L5",
                  label: "L5"
                },
                {
                  id: "L3+",
                  label: "L3+"
                }
              ]
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-"
                },
                {
                  id: "M4",
                  label: "M4"
                },
                {
                  id: "M2",
                  label: "M2"
                },
                {
                  id: "M1",
                  label: "M1"
                },
                {
                  id: "L4-",
                  label: "L4-"
                },
                {
                  id: "L1",
                  label: "L1"
                }
              ]
            },
            {
              id: "M7",
              label: "M7"
            }
            // {
            //   id: "N/A",
            //   label: "N/A"
            // },
            // {
            //   id: "DND",
            //   label: "DND"
            // }
          ]
        }
      ]
    };
  },
  components: {
    vSelect,
    Treeselect,
    DateRangePicker
  },
  computed: {
    generateName() {
      let name = `Select a date range for ${this.selected_enrollment} enrolment`;
      return name;
    },
    checkEnrollmentSelection() {
      if (this.selected_enrollment) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    city(value) {
      this.loadSpocs(value);
    },
    open(value) {
      if (!value) {
        this.selected_enrollment = "";
      }
    },
    selected_enrollment(value) {
      if (value === "CPA" || value === "CMA") {
        this.selectedSpoc.levelsSelected = [];
        this.dateRange.startDate = "";
        this.dateRange.endDate = "";
      }
    }
  },
  mounted() {
    this.setStyling();
    this.getSpocs();
    document.getElementById("manageQueue").children[1].style.marginBottom =
      "25%";
  },
  methods: {
    removeUnrequiredOptions() {
      // console.log(value);
      if (
        this.selected_enrollment === "CPA" ||
        this.selected_enrollment === "CMA"
      ) {
        this.selected_enrollment = "";
        this.dateRange.startDate = "";
        this.dateRange.endDate = "";
      }
    },
    setStyling() {
      let element = document.getElementsByClassName(
        "vue-treeselect__menu-container"
      );
      for (let item of element) {
        console.log(item.id);
      }
      //   console.log();
    },
    openPopup(tr) {
      this.selectedSpoc = tr;
      // this.selected_enrollment = "";
      // console.log(tr);
      tr.levelsSelected = [];
      if (tr.queue_level === "all") {
        tr.levelsSelected.push("All");
      } else if (tr.queue_level === "cpa" || tr.queue_level === "cma") {
        this.selected_enrollment = tr.queue_level.toUpperCase();
      } else {
        tr.levelsSelected = tr.queue_level.split(",");
      }
      // this.spoc_names.forEach(spoc => {
      //   console.log(spoc);
      //   spoc.levelsSelected = [];
      //   if (spoc.queue_level === "all") {
      //     spoc.levelsSelected.push("All");
      //   } else {
      //     spoc.levelsSelected = spoc.queue_level.split(",");
      //   }
      // });
      this.open = true;
    },
    loadSpocs(cityName) {
      this.CitySpocs = [];
      this.spoc_names.forEach(spoc => {
        if (spoc.city === cityName) {
          this.CitySpocs.push(spoc);
        }
      });
    },
    getSpocs() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          //   console.log(response.data.spocs);
          this.spoc_names = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    applySetting(tr) {
      let url = `${constants.SERVER_API}manageQueue`;
      let obj = {
        spoc_id: tr.id,
        focus_on:
          this.selected_enrollment === "CPA" ||
          this.selected_enrollment === "CMA"
            ? this.selected_enrollment.toLowerCase()
            : this.Getlevel(tr.levelsSelected),
        focus_enrol_from: this.datatoTimestamp(this.dateRange.startDate),
        focus_enrol_to: this.datatoTimestamp(this.dateRange.endDate)
      };
      console.log(obj);
      // return;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          //   console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.spoc_names.forEach(spoc => {
              if (spoc.id === tr.id) {
                if (
                  this.selected_enrollment === "CPA" ||
                  this.selected_enrollment === "CMA"
                ) {
                  spoc.queue_level = this.selected_enrollment.toLowerCase();
                } else {
                  spoc.queue_level = this.Getlevel(tr.levelsSelected);
                }
                // spoc.queue_level
              }
            });
            this.open = false;
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    Getlevel(initSelectedLevels) {
      let levels = [];
      initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          //   this.treeDataLevels[0].children[0].children.forEach(child => {
          //     levels.push(child.label);
          //   });
          //   this.treeDataLevels[0].children[1].children.forEach(child => {
          //     levels.push(child.label);
          //   });
          //   this.treeDataLevels[0].children[2].children.forEach(child => {
          //     levels.push(child.label);
          //   });
          levels.push("all");
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      return levels.join();
    },
    extractSpocByCity(response) {
      var unique = response
        .map(name => {
          return {
            count: 1,
            name: name.city
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if(city_options.includes(key)){
            this.cities.push(key);
          }
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      this.city = this.cities[0];
      // this.calculateTime();
    }
  }
};
</script>

<style>
/* .vue-treeselect__menu--active {
  z-index: 100000;
} */
</style>
